import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min.js';
// for vite, moment import from the dist folder are necessary. See https://github.com/moment/moment/issues/5926#issuecomment-1056785251
import 'moment/dist/locale/cs';
import 'moment/dist/locale/da';
import 'moment/dist/locale/de';
import 'moment/dist/locale/es';
import 'moment/dist/locale/fi';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/hr';
import 'moment/dist/locale/it';
import 'moment/dist/locale/lt';
import 'moment/dist/locale/lv';
import 'moment/dist/locale/nl';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/pt';
import 'moment/dist/locale/ru';
import 'moment/dist/locale/sv';
import 'moment/dist/locale/tr';

const configureMoment = ({ language, facilityTimeZone }) => {
  moment.locale(moment.locales().includes(language) ? language : 'en');
  if (facilityTimeZone) {
    moment.tz.setDefault(facilityTimeZone);
  }

  const locale = moment.locale();
  const localeData = moment.localeData();
  moment.updateLocale(locale, {
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: localeData.longDateFormat('L'),
      LL: localeData.longDateFormat('LL'),
      LLL: localeData.longDateFormat('LLL'),
      LLLL: localeData.longDateFormat('LLLL'),
    },
  });
  return moment;
};

export default configureMoment;
