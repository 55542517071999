<template>
  <b-field v-bind:type="wasTouched && !isValid ? 'is-danger' : undefined">
    <div class="widget-phone-input-field">
      <!-- add random pattern if number is invalid in order to trigger form error -->
      <b-input
        ref="input"
        type="tel"
        class="widget-phone-input-field__input"
        v-bind:pattern="!isValid ? Date.now().toString() : undefined"
        v-bind="$attrs"
        v-bind:placeholder="placeholder"
        v-bind:required="required"
        v-on="{
          ...$listeners,
          input: handleInput,
          blur: handleBlur,
        }"
      />
    </div>
  </b-field>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

export default {
  inheritAttrs: false,
  props: {
    value: [Number, String],
    placeholder: String,
    required: {
      type: Boolean,
      default: false,
    },
    initialCountry: String,
    utilsScript: String,
  },
  data() {
    return {
      isValid: false,
      wasTouched: false,
    };
  },
  methods: {
    handleBlur() {
      this.wasTouched = true;
    },
    handleInput() {
      this.isValid = this.iti.isValidNumber();
      this.$listeners.input(this.iti.getNumber(), this.isValid);
    },
    handleCountryChange() {
      this.handleInput(this.value);
    },
  },
  mounted() {
    const { input } = this.$refs.input.$refs;
    input.value = this.value;
    input.addEventListener('countrychange', this.handleCountryChange);
    this.iti = intlTelInput(input, {
      autoPlaceholder: 'off',
      initialCountry: this.initialCountry,
      countrySearch: false,
      utilsScript: this.utilsScript,
      validationNumberType: 'FIXED_LINE_OR_MOBILE',
      formatAsYouType: false,
    });
  },
  beforeDestroy() {
    const { input } = this.$refs.input.$refs;
    input.removeEventListener('countrychange', this.handleCountryChange);
  },
};
</script>

<style lang="scss">
@import '../../styles/_variables.scss';

body {
  [data-theme='broadway'] ~ .iti--fullscreen-popup.iti--container {
    .iti__dropdown-content {
      border: 1px solid;
      border-radius: 20px;
    }
  }
  .iti--fullscreen-popup.iti--container {
    .iti__dropdown-content {
      border: 1px solid;
    }
  }
  .iti--fullscreen-popup.iti--container {
    background-color: transparent;
    top: 15%;
    bottom: 15%;
  }
  .iti__dropdown-content {
    background: var(--theme-background-color);
    border-color: var(--theme-accent-color) !important;
    border-radius: 0px;
    margin-top: 0px !important;
    border-radius: 10px;
  }
  .iti__selected-country-primary {
    background-color: initial !important;
  }
  .iti__selected-country:focus-visible {
    outline: none;
    @at-root #{$glass} & {
      box-shadow: 0 0 0.5rem var(--theme-accent-color);
      box-shadow: 0 0 0.5rem var(--theme-accent-color);
      outline: none;
      box-shadow: 0 0 0.5rem var(--theme-accent-color);
      outline: none;
    }

    @at-root #{$broadway} & {
      box-shadow: 0 0 0.5rem var(--theme-accent-color);
    }
  }
  .iti__country-container {
    @at-root #{$glass} & {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    @at-root #{$broadway} & {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}
.widget-phone-input-field {
  & .iti__country-container {
    @at-root #{$glass} & {
      background: var(--theme-accent-color);
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      border-bottom: none;
    }
  }
  & input {
    @at-root #{$glass} & {
      background: linear-gradient(
        to right,
        transparent 10px,
        var(--theme-accent-color) 10px
      );
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: none;
    }
  }
}
.widget-phone-input-field {
  position: relative;

  border-bottom: 1px solid #dbdbdb;

  &:focus-within {
    border-color: var(--theme-accent-color);

    @at-root #{$glass} & {
      border: none;
    }
  }

  @at-root #{$broadway} & {
    border: 2px solid var(--theme-accent-color);
    border-radius: 20px;
  }

  & .iti__arrow {
    border-top-color: var(--theme-font-color);
    border-bottom-color: var(--theme-font-color);

    @at-root #{$glass} & {
      border-top-color: var(--theme-background-color);
      border-bottom-color: var(--theme-background-color);
    }
    @at-root #{$broadway} & {
      border-top-color: var(--theme-accent-color);
      border-bottom-color: var(--theme-accent-color);
    }
  }
  & .input {
    border: none;

    @at-root #{$broadway} & {
      border-radius: 20px;
    }

    @at-root #{$glass} & {
      color: var(--theme-background-color);
      caret-color: var(--theme-background-color) !important;
      &::placeholder {
        color: var(--theme-background-color) !important;
      }
    }
  }
}
.widget-phone-input-field--required {
  padding-left: 0.2rem;
}
.widget-phone-input-field__placeholder {
  position: absolute;
  top: 0.5rem;
  left: 52px;
}
.widget-phone-input-field__input {
  width: 100%;

  input::placeholder {
    color: var(--theme-font-color) !important;
    filter: opacity(0.5);
  }
  input:focus {
    &::placeholder {
      filter: opacity(1);
    }
  }
}

.iti {
  display: flex;
}
</style>
