<template>
  <div class="widget-contact-content">
    <div class="widget-contact-body" ref="body">
      <div class="widget-contact-body-top">
        <div v-on:click="handleBackClick" class="widget-contact-header">
          <w-info-header :state="state"></w-info-header>
        </div>
        <div class="widget-contact-name">
          <div class="widget-contact-name-first">
            <span class="widget-contact-input-title">{{ txtFirstName }}*</span>
            <w-input
              ref="autofocus"
              autofocus
              required
              type="text"
              v-model="state.formValues.firstName"
              v-bind:placeholder="txtFirstNamePlaceholder"
              v-bind:disabled="state.isSavingBooking"
              @blur="trimFirstName"
            />
          </div>
          <div class="widget-contact-name-last">
            <span class="widget-contact-input-title">{{ txtLastName }}*</span>
            <w-input
              required
              v-model="state.formValues.lastName"
              type="text"
              v-bind:placeholder="txtLastNamePlaceholder"
              v-bind:disabled="state.isSavingBooking"
              @blur="trimLastName"
            />
          </div>
        </div>
        <div class="widget-contact-email">
          <span class="widget-contact-input-title">{{ txtEmail }}*</span>
          <w-input
            expanded
            required
            v-model="state.formValues.email"
            type="email"
            v-bind:placeholder="txtEmailPlaceholder"
            v-bind:disabled="state.isSavingBooking"
          />
        </div>
        <div
          :class="{
            'widget-contact-phone': true,
            'widget-contact-phone-fullscreen': state.fullscreen,
          }"
        >
          <span class="widget-contact-input-title">{{ txtPhone }}*</span>
          <w-phone-input
            required
            v-bind:placeholder="txtPhonePlaceholder"
            v-bind:disabled="state.isSavingBooking"
            v-bind:initialCountry="state.facilityCountry"
            v-bind:utilsScript="`${state.staticUrl}js/widgetItiUtils.js`"
            v-bind:value="state.formValues.phone"
            v-on:input="state.setPhone"
          />
        </div>
        <w-honeypot
          v-if="
            this.state.isBookingDepositDisabled(
              this.state.selectedAvailability.date
            )
          "
          :state="state"
        >
        </w-honeypot>
        <div
          v-if="
            state.facebookLogin &&
            !state.facebookAccessToken &&
            state.facebookInitialized &&
            !state.isAddingWaitlist
          "
          class="widget-contact-extras"
        >
          <div class="widget-contact-extras-label">
            {{ txtBookingExtras }}
          </div>
          <div class="widget-contact-extras-buttons">
            <div class="widget-fb" v-on:click.prevent="handleFbLogin">
              <div class="widget-fb-logo">
                <div class="widget-fb-img"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showFacebookError" class="widget-save-contact-error">
          <b-message type="is-danger">
            {{ txtFacebookError }}
          </b-message>
        </div>
        <div
          v-if="!state.isAddingWaitlist && state.newsletterSignup"
          class="widget-contact-offer"
        >
          <b-field>
            <b-checkbox
              v-model="state.formValues.subscribe"
              v-bind:disabled="state.isSavingBooking"
              class="newsletter-checkbox"
            >
              <span v-html="txtOffer"></span>
            </b-checkbox>
          </b-field>
        </div>
      </div>
      <div v-if="errorMessage" class="widget-save-contact-error">
        <b-message type="is-danger">
          {{ errorMessage }}
        </b-message>
      </div>
    </div>
  </div>
</template>

<script>
import escape from 'lodash/escape'; // direct import for proper tree shaking

import i18n from '../i18n';

import InputField from './common/FormInputField';
import PhoneInputField from './common/PhoneInputField.vue';
import InfoHeader from './common/InfoHeader.vue';
import Honeypot from './Honeypot.vue';

export default {
  props: ['state'],
  components: {
    'w-input': InputField,
    'w-phone-input': PhoneInputField,
    'w-honeypot': Honeypot,
    'w-info-header': InfoHeader,
  },
  data: () => {
    return {
      txtFirstName: i18n.gettext('First name'),
      txtFirstNamePlaceholder: i18n.gettext('e.g. John'),
      txtLastName: i18n.gettext('Last name'),
      txtLastNamePlaceholder: i18n.gettext('e.g. Smith'),
      txtEmail: i18n.gettext('Email'),
      txtEmailPlaceholder: i18n.gettext('e.g. example@gmail.com'),
      txtPhone: i18n.gettext('Phone'),
      txtPhonePlaceholder: i18n.gettext('e.g. (0)151 1234567'),
      txtComment: i18n.gettext('Optional message'),
      txtFacebookError: i18n.gettext(
        'There was a problem connecting to facebook.'
      ),
    };
  },
  mounted() {
    window.gtag?.('event', 'screen_view', {
      app_name: 'widget',
      screen_name: 'contact',
    });
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        this.onCommentEnterPress(event);
      }
    });
  },
  updated() {
    if (this.state.bookingError) {
      let body = this.$refs.body;
      body.scrollTo(0, body.scrollHeight);
    }
    if (this.state.facebookAccessToken && this.$refs.autofocus) {
      this.$refs.autofocus.checkHtml5Validity();
    }
  },
  computed: {
    txtOffer: function () {
      const text = this.state.facilityNewsletterSignupText
        ? this.state.facilityNewsletterSignupText.replaceAll(
            '%s',
            this.state.facilityName
          )
        : i18n.sprintf(
            i18n.gettext(
              'I want to receive exclusive offers via email from %s.'
            ),
            this.state.facilityName
          );
      // Feature for Hans im Glück: replace links markdown style
      // [Visit our website](https://example.com) -> <a href="http://example.com" ... >Visit our website</a>
      return escape(text).replaceAll(
        /\[(.+)\]\((.+)\)/g,
        (match, linkText, linkUrl) => {
          try {
            linkUrl = new URL(linkUrl);
            if (linkUrl.protocol === 'javascript:') {
              throw new Error('javascript not allowed');
            }
          } catch {
            return match;
          }
          return `<a href="${linkUrl}" target="_blank" rel="noopener nofollow">${linkText}</a>`;
        }
      );
    },
    txtFormattedNum: function () {
      return i18n.sprintf(
        i18n.ngettext('1 Person', '%1d People', this.state.formValues.num),
        this.state.formValues.num
      );
    },
    txtBookingExtras: function () {
      return `${i18n.gettext('Or book with')}:`;
    },
    errorMessage: function () {
      return this.state.bookingError;
    },
    showFacebookError: function () {
      return this.state.facebookError;
    },
  },
  methods: {
    trimFirstName: function () {
      this.state.formValues.firstName = this.state.formValues.firstName.trim();
    },
    trimLastName: function () {
      this.state.formValues.lastName = this.state.formValues.lastName.trim();
    },
    handleFbLogin: function () {
      this.state.onFacebookLogin();
    },
    onCommentEnterPress: function (e) {
      e.stopPropagation();
      e.preventDefault();
    },
    handleBackClick: function () {
      this.state.stepForward = false;
      this.state.step = 'BOOKING';
    },
  },
};
</script>

<style lang="scss">
@import '../styles/_variables.scss';

.widget-contact-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
}

.widget-contact-header {
  padding-top: 0.5rem;
  cursor: pointer;
}

.widget-contact-header-custom {
  padding: 5px;
}

.widget-contact-body {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .help {
    display: none;
  }

  @at-root #{$broadway} &,
    #{$broadway} & {
    height: 100%;
    justify-content: space-between;
  }
}

.widget-contact-body-top {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1.5rem 0.75rem 1.5rem;

  @at-root #{$broadway} & {
    border: none;
    flex: none;
  }
}

.widget-contact-booking-summary {
  padding-bottom: 5px;
  color: var(--theme-accent-color);
}

.widget-contact-input-title {
  font-size: 0.75rem;
  color: var(--theme-font-color-opacity-5);

  @at-root #{$broadway} &,
    #{$glass} & {
    color: var(--theme-accent-color);
  }
}

.widget-contact-name {
  display: flex;
}

.widget-contact-name-first {
  flex: 1 1 45%;
  padding-right: 10px;
}

.widget-contact-name-last {
  flex: 1 1 50%;
}

.widget-contact-phone {
  @at-root #{$broadway} & {
    border-radius: 10px;
  }

  @at-root #{$glass} & {
    border-radius: 10px;
  }
}

.widget-contact-offer {
  font-size: 0.8em;
  line-height: 15px;
  padding: 15px 0px;
  margin: 0 5px;
}

.widget-save-contact-error {
  padding-top: 10px;
}

.widget-contact-extras {
  display: flex;
  padding-top: 10px;
  margin: 0 5px;

  @at-root #{$broadway} & {
    color: var(--theme-font-color);
  }
}

.widget-contact-extras-label {
  flex: 1 1 auto;
}

.widget-contact-extras-buttons {
  flex: 0 0 auto;
}

.newsletter-checkbox {
  display: flex;
  align-items: flex-start !important;
}

.widget-contact-name-first,
.widget-contact-name-last,
.widget-contact-email,
.widget-contact-comment {
  @at-root #{$broadway} & {
    > div {
      .input {
        border-bottom: none;
      }
    }
  }
  @at-root #{$glass} & {
    > div {
      background: var(--theme-accent-color);
      border-radius: 10px;
      .input {
        border-bottom: none;
        color: var(--theme-background-color);

        caret-color: var(--theme-background-color) !important;
        &::placeholder {
          color: var(--theme-background-color) !important;
        }
      }
    }
  }
}
</style>
